<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card shadow="never">
      <div slot="header" class="a-fs-16 a-fw-b">订单列表</div>
      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table
          ref="deviceList"
          :data="tableData"
          max-height="580"
          :highlight-current-row="true"
          style="width: 100%"
        >
          <el-table-column prop="orderId" label="订单ID" min-width="150"></el-table-column>
          <el-table-column prop="deviceCode" label="设备号" min-width="103"></el-table-column>
          <el-table-column prop="stationName" label="订单时长" min-width="103">
            <template slot-scope="{ row }">
              <span>{{ row.realMinutes }}分钟</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTimeText" label="下单时间" min-width="103"></el-table-column>
          <el-table-column prop="stationName" label="订单金额" min-width="103">
            <template slot-scope="{ row }">
              <span>￥{{ util.numFormat(row.amount) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
import util from "../../utils/util.js";
export default {
  data() {
    return {
      util: util,
      tableData: [],
      pageParam: {
        url: this.$Config.apiUrl.getOrderList,
        method: "post",
        params: {
          stationId: "", // 站点id
          balanceDate: "", // 账单日期
        },
        freshCtrl: -1,
      },
    };
  },
  mounted() {
    this.pageParam.params.stationId = this.$route.query.stationId;
    this.pageParam.params.balanceDate = this.$route.query.balanceDate;
    this.pageParam.freshCtrl++;
  },
  methods: {
    setTableData(datas) {
      this.tableData = datas;
    },
  },
};
</script>

<style lang="scss" scoped></style>
